import { inject, type InjectionKey, provide } from 'vue'
import type { FragmentType } from '~/resources/graphql'
import type { PlanType } from '~/resources/graphql/graphql'

export type KioskSuccessData =
    | {
          includedInvoice: false
          wasGuest: boolean
          data: {
              referenceNumber: number
              planType: PlanType
              firstName: string
          }
      }
    | {
          includedInvoice: true
          wasGuest: boolean
          data: FragmentType<typeof CheckoutSuccessFragment>
      }
export type KioskSuccessFunction = (data: KioskSuccessData) => void

const kioskSuccessInjectionKey: InjectionKey<KioskSuccessFunction> =
    Symbol('KIOSK_SUCCESS')
export function useKioskSuccess() {
    return inject(kioskSuccessInjectionKey, () => {
        throw new Error()
    })
}

export function provideKioskSuccessFunction(fn: KioskSuccessFunction) {
    provide(kioskSuccessInjectionKey, fn)
}
